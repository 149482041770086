<template>
  <div
    class="contact_box fixed-bottom container"
    ref="Contact"
    id="Contact"
    :style="{ transform: `translateY(${translate})` }"
  >
    <div class="title flex-center">
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".2s"
      >
        C
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".3s"
      >
        o
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".4s"
      >
        n
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".5s"
      >
        t
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".6s"
      >
        a
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".7s"
      >
        c
      </div>
      <div
        class="ml-10 wow animate__animated animate__slideInUp"
        data-wow-duration=".8s"
      >
        t
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration=".9s"
      >
        U
      </div>
      <div
        class="wow animate__animated animate__slideInUp"
        data-wow-duration="1s"
      >
        s
      </div>
    </div>
    <div class="slogan">联系我们</div>
    <div class="content">
      <div class="form">
        <!-- <form action="https://formspree.io/f/xlevbalq" method="post" id="content" onsubmit="return false;"> -->
        <!-- <form action="https://formspree.io/f/xlevbalq" method="post" id="content"> -->
        <!-- <form id="content"> -->
          <div class="top">
            <div class="input_box">
              <div class="label">Name<span class="required">*</span></div>
              <div class="input">
                <input type="text" name="name" v-model="form.Name" class="name" />
              </div>
            </div>
            <div class="input_box">
              <div class="label email-label">E-mail<span class="required">*</span></div>
              <div class="input">
                <input type="text" name="email" v-model="form.Email" class="email" />
              </div>
            </div>
          </div>
          <div class="textarea">
            <div class="input_box">
              <div class="label">Message<span class="required">*</span></div>
              <div class="input">
                <textarea name="textarea"  v-model="form.Message" cols="30" rows="10"></textarea>
              </div>
            </div>
          </div>
          <div class="button">
            <button class="send" @click="sendMessage" type="submit">Send Message</button>
            <button class="reset" @click="reset" type="submit">reset</button>
          </div>
        <!-- </form> -->
      </div>
      <div class="info">
        <div class="info_item">
          <div class="header">
            <div class="icon">
              <img
                :src="require('@/assets/images/position.png')"
                class="position"
              />
            </div>
            <div class="name">Address</div>
          </div>
          <div class="details">
            <div>上海市普陀区大渡河路168弄26号K棟506, TN 200062</div>
            <div>广州市番禺区海伦堡创意园1座201室，511490</div>
          </div>
        </div>
        <div class="info_item">
          <div class="header">
            <div class="icon">
              <img :src="require('@/assets/images/phone.png')" class="phone" />
            </div>
            <div class="name">Phone</div>
          </div>
          <div class="details">
            <div>+021 000 0000 0000 / +021 000 0000 0000</div>
            <div>+021 0000 0000 (FAX)</div>
          </div>
        </div>
        <div class="info_item">
          <div class="header">
            <div class="icon">
              <img :src="require('@/assets/images/email.png')" class="email" />
            </div>
            <div class="name">E-mail</div>
          </div>
          <div class="details">
            <div>bd@orange9.net</div>
            <div>bd@orange9.net</div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy_right">
      <p class="copyright-text" data-v-133b94ce="">
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          data-v-133b94ce=""
        >
        沪ICP备2022020906号
        </a>
        Copyright © 2020-2023 橙玖科技-橙玖官网 版权所有
        <a href="http://www.orange9.com.cn/" target="_blank" data-v-133b94ce=""
          >orange9</a
        >
      </p>
    </div>
  </div>
</template>

<script>

import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
export default {
  name: 'HomeContact',
  // components:{ Form , FormItem },
  data() {
    return {
      form: {
        Name:'',
        Email:'',
        Message:'',
        Send_Email:'1035551363@qq.com'
      },
      translate: '-100%',
      height: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    this.$nextTick(() => {
      this.height = this.$refs.Contact.getBoundingClientRect().height
    })
  },
  methods: {
    handleScroll() {
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      let bottom =
        scrollHeight -
        scrollTop  -
        clientHeight
      if (bottom <= this.height) {
        let num = this.height - bottom
        if (bottom <= 0) {
          this.translate = 0
        } else if (bottom > 0) {
          this.translate = `calc( -100% + ${num}px )`
          console.log(this.translate,'222');
        }
      }
    },
    //发送邮件
    async sendMessage() {
      // e.preventDefault()
      if (this.form.Name && this.form.Message) {
        if (this.isEmail(this.form.Email)) {
          let data = {Name:this.form.Name,Email:this.form.Email,Message:this.form.Message,Send_Email:this.form.Send_Email}
          try{
            const res = await this.$axios.post("http://139.196.141.94:8083/mail",data)
            // console.log(res.data.msg);
            console.log(res);
            Message.success('邮件发送成功')
            this.form = {}
          }catch(e){
            console.log(e.message);
          }
        } else {
          Message.error('请填写正确的E-mail地址')
        }
      } else {
        Message.error('请将表格信息填写完整')
      }
    },
    //清空
    reset() {
      this.form = {}
    },
    //邮件正则校验
    isEmail(strEmail) {
      if (
        strEmail.search(
          /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
        ) != -1
      ) {
        return true
      }
    },
  },
}
</script>

<style scoped lang="scss">
// .fixed-bottom {
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
// }
.contact_box {
  width: 100%;
  height: 100%;
  background-color: #1d1d1d;
  padding-top: 120px;
  // transition: all 0.1s linear;
  .title {
    font-size: 77px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .slogan {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e95513;
  }
  .content {
    display: flex;
    padding-bottom: 40px;
    margin-top: 42px;
    border-bottom: 1px solid #ffffff;
    .form {
      flex: 0 0 50%;
      color: #fff;
      .input_box {
        .label {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 10px;
          .required {
            color: #e95513;
          }
        }

        input,
        textarea {
          width: 164px;
          height: 40px;
          background: #262626;
          outline: none;
          border: none;
          color: #fff;
          padding: 0 5px;
        }
      }
      .top {
        display: flex;
        margin-bottom: 25px;
        .email {
          width: 244px;
          height: 40px;
          background: #262626;
          margin-left: 14px;
        }
      }
      .textarea {
        color: #fff;

        textarea {
          color: #fff;
          width: 422px;
          height: 108px;
          background: #262626;
        }
      }
      .button {
        margin-top: 23px;
        display: flex;
        .send {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1d1d1d;
          width: 152px;
          height: 30px;
          background: #e95513;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
        .reset {
          margin-left: 11px;
          width: 81px;
          height: 30px;
          background: #1d1d1d;
          border: 1px solid #545454;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #545454;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
      }
    }
    .info {
      flex: 0 0 50%;
      color: #fff;

      .info_item {
        padding-bottom: 18px;
        border-bottom: 1px solid #545454;
        &:nth-last-child(1) {
          border-bottom: 0;
        }
        &:nth-child(1) {
          .header {
            margin-top: 0;
          }
        }
        .header {
          margin-bottom: 17px;
          margin-top: 20px;
          display: flex;
          align-items: center;
          .icon {
            display: flex;
            align-items: center;
            margin-right: 5px;
          }
          .position {
            width: 11px;
            height: 13px;
          }
          .phone {
            width: 10px;
            height: 14px;
          }
          .email {
            width: 12px;
            height: 8px;
          }
          .name {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e95513;
          }
        }
        .details {
          div {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #d9d9d9;
          }
        }
      }
    }
  }
  .copy_right {
    padding: 30px 0;
    text-align: center;
    font-size: 9px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    a {
      color: #e95513;
    }
  }
}
.email-label {
  margin-left: 14px;
}
.ml-10 {
  margin-right: 30px;
}
</style>
