<template>
  <div class="works_box" ref="Works" id="Works">
    <div class="container">
      <div class="head-title flex-center">
        <div class="wow animate__animated animate__slideInUp" data-wow-duration=".2s">
          W
        </div>
        <div class="wow animate__animated animate__slideInUp" data-wow-duration=".3s">
          o
        </div>
        <div class="wow animate__animated animate__slideInUp" data-wow-duration=".4s">
          r
        </div>
        <div class="wow animate__animated animate__slideInUp" data-wow-duration=".5s">
          k
        </div>
        <div class="wow animate__animated animate__slideInUp" data-wow-duration=".6s">
          s
        </div>
      </div>
      <div class="slogan">案例展示</div>
    </div>
    <!-- 文字图片切换 -->
    <div class="content">
      <!-- 左侧文字部分 -->
      <div ref="left" class="left container-l">
        <div class="switch">
          <div @click="handlePre" class="pre" data-wow-duration="2s"></div>
          <div @click="handleNext" class="next"></div>
        </div>
        <div class="wow animate__animated animate__fadeInLeft">
          <div class="wow animate__animated animate__fadeInLeft" v-if="showText" data-wow-duration="0.1s">
          <div class="title">
            {{textList1[textIndex]}}
        </div>
        <div class="line">
          <img :src="require('@/assets/images/line.png')" alt="" srcset="" />
        </div>
        <div class="details ">
          <div class="text">{{textList[textIndex]}}</div>
        </div>
        </div>
        </div>
      </div>
      <!-- 右侧图片部分 -->
      <div class="right" ref="right">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="(item, i) in 5" :key="i" class="swiper-slide">
              <img :src="require(`@/assets/images/img${i + 1}.png`)" width="100%" height="100%"  />
            </div>
          </div>
          <div class="swiper_bg" :style="{
              transform: `translateX(${translate * 2}px)`,
              transition: `all ${time}s linear`,
            }"></div>
        </div>
      </div>
    </div>
    <!-- 切换logo -->
    <div class="container works_img">
      <demo v-for="(item, index) in used" :key="index" :nextIndex="nextIndex" :other="other" :cur="item" :allImage="images" :indexs="indexs" class="img1" @change="(e)=>handleChangeImg(e,index)" />
    </div>
    <!-- 企业资质 -->
    <div class="containers container">
      <div class="text">
        Qualifications
      </div>
      <div class="textslo">
        企业资质
      </div>
      <div class="footers">
        <div class="footerFather">
          <img :src="require('@/assets/images/images1.png')"  width="90%" height="90%" />
          <div class="footerColor">
            <img :src="require('@/assets/images/orangecolor.png')" width="80%" height="70%" />
            <div class="text">质量管理体系认证证书</div>
          </div>
        </div>
        <div class="footerFather">
          <img :src="require('@/assets/images/images2.png')" width="90%" height="90%" />
          <div class="footerColor">
            <img :src="require('@/assets/images/orangecolor.png')" width="80%" height="70%" />
            <div class="text">质量管理体系认证证书</div>
          </div>
        </div>
        <div class="footerFather">
          <img :src="require('@/assets/images/images3.png')" width="90%" height="90%" />
          <div class="footerColor">
            <img :src="require('@/assets/images/orangecolor.png')" width="80%" height="70%" />
            <div class="text">信息安全管理体系认证证书</div>
          </div>
        </div>
        <div class="footerFather">
          <img :src="require('@/assets/images/images4.png')" width="90%" height="90%" />
          <div class="footerColor">
            <img :src="require('@/assets/images/orangecolor.png')" width="80%" height="70%" />
            <div class="text">信息安全管理体系认证证书</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import demo from "./demo.vue"
export default {
  name: "HomeWork",
  components: { demo },
  data () {
    return {
      swiper: null,
       textList1:[
         '星巴克私域内容营销',
         'DSM集团-康萃乐益生菌',
         '天猫国际超囤日-职人冻冻节',
         '天猫国际全球健康节',
         'iSDG八周年明星营销案例',
        //  '格力高与天猫打造艺术家联名限量款',
        ],
      textList:[
         '星巴克50年的品牌精神，创新打造“第三空间”的消费体验。橙玖科技与星巴克一起营造星巴克有温度，有内涵的线上“第四空间”，将消费者门店美好感受延伸到私域的奇妙体验，在体验中感受星巴克的人文精神。',
         '康萃乐是帝斯曼集团麾下强势发展的全球益生菌领导品牌。康萃乐为中国消费者提供多样化产品，连续6年全球销量领先。橙玖科技与帝斯曼集团深入合作，为康萃乐提供多方位品牌服务，包括品牌营销、品牌私域建设、消费者健康服务、数据服务等。',
         '天猫国际保健×美妆强强联手，首次共同击穿熬夜加班人群聚集多品类多品牌，7大品牌环环相连，营销一体聚力超强人气助力卸妆洁面类目对比日销增长近100%保健熬夜加班类目增长400%+快闪店总体曝光数：2W+，快闪店进店人数：10000+',
         '聚焦保健运动纤体人群，品类击穿聚力运动纤体多品类多品牌，9大品牌强强联合《这就是灌篮》IP营销+划算日联聚合线上线下超强人气，品销一体活动期间保健大盘GMV增长近4倍叶子类目爆发系数：160%+',
         'iSDG作为日本知名健康品牌，自成立以来始终秉承着“健康生活，源于饮食”的理念与“尽吾之力,悦客之心”的服务宗旨，经过8年品牌沉淀，iSDG已遍及日本20000家药妆店，签约关晓彤为品牌代言人；以“国际品质，精益求精”的态度传递优质服务与健康快乐。',
        //  '格力高与天猫双十一联合打造的艺术家联名限量款产品。 围绕构建一次百奇嘉年华，一次有欢乐体感的百奇购物节，强化百奇节核心产品影响力。',
        ],
        images: [
        "works1",
        "works9",
        "works2",
        "works4",
        "work11",
        "works7",
        "works10",
        "works8",
        "works5",
        "work12",
        "work14",
        "work15",
      ],
      other: [],
      used: [],
      nextIndex: 6,
      textIndex:0,
      showText:true,
      indexs: [],
      time: 1.2,
      width: "",
      translate: 0,
    };
  },
  created () {
    this.used = this.images.slice(0, 5);
    this.indexs = [5, 6, 7, 8, 9, 10]  // 待选图片
    this.nextIndex = this.indexs[0] // 下一张待选图片
    this.other = this.images.filter((e) => {
      return !this.used.includes(e);
    });
    console.log("1" + this.used)
    console.log("2" + this.indexs)
    console.log("3" + this.other)
  },
  mounted () {
    this.init();
    console.log(this.other);
  },
  methods: {
    init () {
      let that = this;
      let left = this.$refs.left.offsetWidth;
      let parent = this.$refs.Works.offsetWidth;
      this.width = `${parent - left}`;
      this.translate = `${-parent - left}`;
      this.$refs.right.style.width = `${parent - left}px`;
      this.swiper = new Swiper(".swiper-container", {
        speed: 1200,
        // direction: 'vertical', // 垂直切换选项
        // mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 4000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        loop: true, // 循环模式选项
        on: {
          init: function (swiper) {
            console.log(swiper);
            //   slide = this.slides.eq(0);
            //   slide.addClass("ani-slide");
          },
          transitionStart: function () {
            that.time = 1.2;
            that.translate = that.width;

            // console.log("transitionStart");
             that.getImgIndex()
          },
          transitionEnd: function () {
            that.time = 0;
            that.translate = -that.width;
            // console.log("transitionEnd");
          },
        },
        // 如果需要分页器
        //   pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true, // 分页器可以点击
        //   },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // 如果需要滚动条
        //   scrollbar: {
        //     el: ".swiper-scrollbar",
        //   },
      });
    },
    handlePre () {
      this.swiper.slidePrev();
      this.getImgIndex();
    },
    handleNext () {
      this.swiper.slideNext();
      this.getImgIndex();
    },
    getImgIndex(){
      this.showText = false;
      const index = document.getElementsByClassName("swiper-slide-active")[0];
      this.textIndex = index.getAttribute('data-swiper-slide-index');
      setTimeout(()=>{
        this.showText = true;
      },200)
      // console.log(index.getAttribute('data-swiper-slide-index'),3)
    },
    handleChangeImg (val) {
      console.info("val=" + val)
      console.info("val=" + this.used)
      let imgIndex = this.images.findIndex(e => e == val)
      this.indexs.splice(0, 1)
      this.indexs.push(imgIndex)
      this.nextIndex = this.indexs[0]
    }
  },
};
</script>

<style scoped lang="scss">
.works_box {
  width: 100%;
  // padding: 125px;
  background-color: #fff;
  position: relative;
  padding-top: 50px;
  padding-bottom: 65px;
  .containers{
    background-color: #f8f8f8;
    margin-top: 40px;
    padding-top: 40px;
    .text{
      text-align: center;
      font-size: 50px;
      font-family: Microsoft YaHei;

    }
    .textslo{
      color: #e95513;
      font-size: 20px;
      margin-top: 15px;
      text-align: center;
      margin-bottom: 40px;

    }
    .footers{
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 20px;
      .footerFather{
        width: 100%;
        height: 100%;
        position: relative;
        .footerColor{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: -20px;
        transform: translateX(-10px);
        .text{
          width: 100%;
          position: absolute;
          left: 40%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 16px;
          color: #fff;
        }

      }

      }

    }
  }
  .head-title {
    width: 255px;
    font-size: 77px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #302e2e;
  }
  .slogan {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e95513;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .left {
      flex: 0 0 calc(40%);
      .switch {
        display: flex;
        margin-bottom: 40px;
        .pre,
        .next {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: #f8f8f8;
          margin: 0 5px;
          background-position: center center;
          background-size: 60% 60%;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        .pre {
          background-image: url("~@/assets/images/arrow-left.png");
        }
        .next {
          background-image: url("~@/assets/images/arrow-right.png");
        }
      }
      .title {
        font-size: 23px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #313232;
        margin-bottom: 35px;
      }
      .line {
        // width: 27px;
        // height: 1px;
        // border: 1px solid #e95513;
        margin-bottom: 25px;
      }
      .details {
          width: 420px;
          // height: 122px;
          // height: 100%;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          // color: #3b3b3d;
          // line-height: 40px;
          margin-top: 30px;
        .text {

          font-size: 14px;
          // height: 100%;
          font-family: Microsoft YaHei;
          // font-weight: 400;
          color: #3b3b3d;
          // padding: 5px 0;
          line-height:1.2;
          margin-right: 80px;
        }
      }
    }
     .right {
      // width: 55.5vw;
      transition: 0.4s all ease;
    }
  }
  .works_img {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    .img1 {
      width: 110px;
      height: 65px;
      padding-top: 15px;
    }
    .img2 {
      width: 185px;
      height: 57px;
    }
    .img3 {
      width: 127px;
      height: 48px;
    }
    .img4 {
      width: 142px;
      height: 62px;
    }
  }
}
.swiper_bg {
  position: absolute;
  background: #e75321;
  width: 200%;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

</style>
