<template>
  <div class="company_box" id="Company">
    <div class="left-box">
      <div class="container-l">
        <div class="title_text flex-center">
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".2s">C</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".3s">o</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".4s">m</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".5s">p</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".6s">a</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".7s">n</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".8s">y</div>
        </div>
        <div class="title_text flex-center">
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".2s">A</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".3s">d</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".4s">v</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".5s">a</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".6s">n</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".7s">t</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".8s">a</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".9s">g</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration="1s">e</div>
          <div class=" wow animate__animated animate__slideInUp" data-wow-duration="1.1s">s</div>
        </div>
        <div class="slogan" data-wow-duration="2s">公司优势</div>
        <div class="good" data-wow-duration="2s">我们擅长什么</div>
        <div class="good_en " data-wow-duration="2s">What we are good at?</div>
      </div>
      <!-- <div class="square" data-wow-duration="2s"></div> -->
    </div>
    <div class="right container-r wow animate__animated animate__fadeInRight" data-wow-duration=".5s">
      <div class="content">
        <div class="left">
          <div class="list_item " data-wow-duration="2s" v-for="(item, index) in list" :key="index">
            <div class="header">
              <div class="img">
                <img :src="require('@/assets/images/' + item.src)" alt="" :class="`img${index}`" srcset="" />
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
            <div class="details">
              <div class="title">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCompany",
  data () {
    return {
      list: [
        {
          title: "技术与创新",
          text: "视觉创意执行",
          src: "icon1.png",
          width: "30px",
          height: "30px",
        },
        {
          title: "数据洞察分析",
          text: "深挖品牌数据资产，驱动品牌全域营销",
          src: "icon2.png",
          width: "35px",
          height: "30px",
        },
        {
          title: "人群全链路营销",
          text: "公域+私域全链路人群运营，品效合一",
          src: "icon3.png",
          width: "30px",
          height: "30px",
        },
        {
          title: "品牌内容营销",
          text: "全域内容营销经验，高转化的营销服务",
          src: "icon4.png",
          width: "30px",
          height: "30px",
        },
        {
          title: "视觉创意执行",
          text: "想得好，同时做得到",
          src: "icon5.png",
          width: "25px",
          height: "35px",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.company_box {
  width: 100%;
  // padding: 125px;
  background-color: #fff;
  position: relative;
  padding-top: 150px;
  display: flex;
  justify-content: space-between;
  .left-box {
    flex: 0 0 calc(60% - 50px);
    .title_text {
      width: 292px;
      font-size: 77px;
      line-height: 77px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #302e2e;
      margin-left: 40px;
      &:nth-child(2){
        margin-bottom: 20px;
      }
    }
    .slogan {
      // width: 200px;
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #e95513;
      margin-bottom: 80px;
      margin-left: 40px;
    }
    .good{
      font-weight: 400;
      margin-bottom: 30px;
      margin-left: 40px;
      font-family: Adobe Heiti Std;
      font-size:44px;
      color: #e95513;

    }
    .good_en {
      font-size: 26px;
      font-family: Adobe Heiti Std;
      font-weight: 700;
      color: #313232;
      margin-bottom: 10px;
      margin-left: 40px;
    }
    // .square {
    //   margin-top: 30px;
    //   // width: 634px;
    //   height: 177px;
    //   background: #e95513;
    // }
  }
  .right {
    flex: 0 0 calc(40% - 50px);
    .content {
      display: flex;
      justify-content: space-between;
      .left{
        .list_item {
          padding-bottom: 45px;
          &:nth-last-child(1) {
            padding-bottom: 0;
          }
          .header {
            display: flex;
            .title {
              font-size: 22px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #313232;
              word-break: break-all;
              margin-bottom: 20px;
              margin-left: 20px;
            }
            .img {
              width: 40px;
              height: 40px;
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #e95513;
              .img0 {
                width: 30px;
                height: 30px;
              }
              .img1 {
                width: 35px;
                height: 30px;
              }
              .img2 {
                width: 30px;
                height: 30px;
              }
              .img3 {
                width: 30px;
                height: 30px;
              }
              .img4 {
                width: 25px;
                height: 35px;
              }
            }
          }
          .details {
            .title {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #3b3b3d;
              word-break: break-all;
              margin-left: 60px;
            }
          }
        }
      }
    }
  }
}
</style>