<template>
  <div class="business_box" id="Business">
    <div class="container">
      <div class="head-title flex-center">
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".2s">B</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".3s">u</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".4s">s</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".5s">i</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".6s">n</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".7s">e</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".8s">s</div>
        <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".9s">s</div>
      </div>
      <div class="slogan">
        主营业务
      </div>
      <div class="head-details">
        <div class="info">
          <div class="info_title">
            帮助企业数字化转型，提供品牌定位策略、
            电商运营、整合营销、人群与社群运营、
            全链路私域运营/增长/闭环解决方案等。
          </div>
          <div class="info_detail">
            Helping companies with digital transformation by providing<br>
            brand positioning strategies,E-commerce operation, <br>
            integrated marketing, crowd and social operation,<br>
            Full-chain private domain operation/growth/closing loop solutions, etc.
          </div>
        </div>
        <div class="circle">
          <div class="box">
            <div class="left">
              <CircleDetail>
                <div class="text">
                  <div>服务</div>
                  <div>各大头部</div>
                  <div>企业</div>
                </div>
              </CircleDetail>
            </div>
            <div class="right">
              <CircleDetail hasOrangeClass>
                <div class="text">
                  <div>15年</div>
                  <div>行业经验</div>
                </div>
              </CircleDetail>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="left container-l wow animate__animated animate__fadeInLeft"  data-wow-duration=".5s">
        <div  class="list_item" v-for="(item, index) in list" :key="index">
          <div class="header">
            <div class="title">{{ item.title }}</div>
            <div class="index">{{ "0" + (index+1) }}</div>
          </div>
          <div class="details">
            <div class="title">{{ item.text }}</div>
            <div class="info">{{ item.details }}</div>
          </div>
        </div>
      </div>
      <div class="right container-rigth wow animate__animated animate__fadeInLeft" data-wow-duration=".5s">
        <div  class="list_item" v-for="(item, index) in list2" :key="index">
          <div class="header">
            <div class="title">{{ item.title }}</div>
            <div class="index">{{ "0" + (index+5) }}</div>
          </div>
          <div class="details">
            <div class="title">{{ item.text }}</div>
            <div class="info">{{ item.details }}</div>
          </div>
        </div>
        <img :src="require('@/assets/images/bgclc.png')" width="100%" height="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import CircleDetail from "./cricle/index";
export default {
  name: "HomeBusiness",
  components: { CircleDetail },
  data () {
    return {
      list: [
        {
          title: "品牌定位策略：",
          text: "品牌定位策略包括品牌传播策略、建立品牌价值模型、电子商务发展",
          details:
            " Brand positioning strategy including brand communication strategy,\nestablishing brand value model, e-commerce development"
        },
        {
          title: "电商运营：",
          text: "品牌旗舰店运营、分销商及渠道管理、跨境及进口品牌托管、电子商务零售平台管理、电子商务创意/营销管理等服务",
          details:
            "Brand flagship store operation, distributor and channel management,\ncross-border and import brand hosting, e-commerce retail platform \nmanagement, e-commerce creative/marketing management and other \nservices",
        },
        {
          title: "整合营销：",
          text: "营销活动策略、整合营销创意、线上传播/投放（站内/外）、线下路演/快闪、线下媒体投放等定制等服务",
          details:
            "Marketing campaign strategy, integrated marketing creative, online \ncommunication/placement (onsite/offsite), offline roadshows/flash \nmobs,Customized services such as offline media placement",
        },
        {
          title: "人群与社群运营：",
          text: "品牌人群画像洞察、人群营销、人群效果分析、忠诚度管理等服务，帮助品牌提升客户粘性和客户价值",
          details:
            "Brand demographic insight, demographic marketing, demographic \neffect analysis, loyalty management and other services to help \nbrands Improve customer stickiness and customer value",
        },
      ],
      list2: [
        {
          title: "数智解决方案：",
          text: "企业数智化转型解决方案、企业数智化运营解决方案、全链路私域运营/增长/闭环解决方案",
          details:
            "Enterprise Digital Intelligence Transformation Solution, Enterprise Digital \nIntelligence Operation Solution, Full Chain Private Domain \nOperation/Growth/Closing Loop Solution",
        },
        {
          title: "个性开发：",
          text: "APP/小程序/网页端 多端开发落地、离线/实时数据打通、技术/数据落地实施/定制开发",
          details:
            "APP/small program/web end multi-terminal development and \nimplementation, offline/real-time data connection, technology/data \nimplementation/Custom Development",
        },
        {
          title: "数据产品服务：",
          text: "为企业提供数据全生命周期产品、提供PB级数据架构及处理、企业营销/分析提供底层支撑",
          details:
            "Provide enterprises with data lifecycle products, petabyte-level data \narchitecture and processing, enterprise marketing/analytics to provide \nunderlying support",
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.business_box {
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-top: 200px;
  .head-title {
    font-size: 77px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #302e2e;
  }
  .slogan {
    margin-top: 10px;
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e95513;
  }
  .head-details {
    display: flex;
    align-items: center;
    .info {
      flex: 0 0 50%;
      .info_title {
        margin-top: 30px;
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 700;
        color: #313232;
        word-break: break-all;
        margin-bottom: 17px;
      }
      .info_detail {
        margin-top: 30px;
        // width: 550px;
        font-size: 15px;
        word-break: break-all;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #313232;
      }
    }
    .circle {
      flex: 0 0 50%;
      .box {
        width: 280px;
        margin: 0 auto;
        display: flex;
        .left {
          transform: translateX(10px);
          .text {
            font-size: 24px;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #313232;
            margin-top: 50%;
            transform: translateY(-50%);
          }
        }
        .right {
          transform: translateX(-10px);
          .text {
            font-size: 24px;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #313232;
            margin-top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .content {
    width:100%;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .left {
      height: 100%;
      flex: 0 0 calc(50% - 50px);
      .list_item {
        // height: 25.7%;
        padding-top: 17px;
        padding-bottom: 60px;
        border-top: 1px solid #999999;
        &:nth-last-child(1) {
          padding-bottom: 0;
        }
        .header {
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 23px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #313232;
            margin-bottom: 34px;
            word-break: break-all;
          }
          .index {
            width: 21px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e95513;
          }
        }
        .details {
          .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3b3b3d;
            margin-bottom: 10px;
            word-break: break-all;
          }
          .info {
            white-space: pre-wrap; 
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #979797;
            word-break: break-all;
          }
        }
      }
    }
    .right {
      height: 100%;
      margin-right: 120px;
      flex: 0 0 calc(50% - 50px);
      .list_item {
        // height: 1.8rem;
        padding-top: 17px;
        padding-bottom: 60px;
        border-top: 1px solid #999999;
        &:nth-last-child(1) {
          padding-bottom: 0;
        }
        .header {
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 23px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #313232;
            margin-bottom: 34px;
            word-break: break-all;
          }
          .index {
            width: 21px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #e95513;
          }
        }
        .details {
          .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3b3b3d;
            margin-bottom: 10px;
            word-break: break-all;
          }
          .info {
            white-space: pre-wrap;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #979797;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>