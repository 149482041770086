<template>
  <div class="wrap">
    <div id="home">
      <div class="home">
        <div class="home-wrap">
          <home-header />
          <home-about />
          <home-idea />
          <home-business />
          <home-company />
          <home-works />
        </div>
      </div>
      <home-contact ref="contact" />
    </div>
  </div>
</template>

<script>
import HomeHeader from "./header/index.vue";
import HomeAbout from "./about/index.vue";
import HomeIdea from "./idea/index.vue";
import HomeBusiness from "./business/index.vue";
import HomeCompany from "./company/index.vue";
import HomeWorks from "./works/index.vue";
import HomeContact from "./contact/index.vue";
export default {
  name: "HomePage",
  components: {
    HomeHeader,
    HomeAbout,
    HomeIdea,
    HomeBusiness,
    HomeCompany,
    HomeWorks,
    HomeContact,
  },
  data () {
    return {
      padding: 0,
    };
  },
  computed: {
    a () {
      return 1
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.padding = this.$refs.contact.$el.offsetHeight;
    });
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.home {
  position: relative;
  z-index: 2;
  // padding-bottom: 718px;
  .home-wrap {
    background: #fff;
  }
}
</style>