<template>
  <div class="fixed-top header_nav_box flex-between-center container">
    <div class="logo">
      <img :src="require('@/assets/images/logo.png')" style="" alt="" />
    </div>
    <div class="mean" @click="show = !show">
      <div class="name">MENU</div>
      <div class="icon">
        <img
          :src="require('@/assets/images/menu.png')"
          class="menu_icon"
          style=""
        />
      </div>
    </div>
    <div
      class="header_target wow animate__animated animate__fadeInRight"
      data-wow-duration=".5s"
      v-if="show"
    >
      <div @click="target('Top')">Top</div>
      <div @click="target('About')">About</div>
      <div @click="target('Idea')">Philosophy</div>
      <div @click="target('Business')">Business</div>
      <div @click="target('Company')">Company</div>
      <div @click="target('Works')">Works</div>
      <div @click="target('Contact')">Contact US</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    target(id) {
      this.handleHref(id);
    },
    handleHref(id) {
      this.$nextTick(() => {
        const jump = document.querySelector(`#${id}`);
        // 获取需要滚动的距离
        const total = jump.offsetTop;
        // 获取网页被卷去的高
        let distance = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
        // let distance = document.documentElement.scrollTop;
        // console.log(document.documentElement.scrollTop);
        // console.log(document.body.scrollTop);
        // console.log(window.pageYOffset);
        // console.log(document.body.scrollTop);
        // 平滑滚动，时长500ms，每10ms一跳，共50跳
        let step = total / 50;
        // console.log(distance, total);

        if (total >= distance) {
          smoothDown();
        } else {
          const newTotal = distance - total;
          console.log(total, distance);
          step = newTotal / 50;
          smoothUp();
        }
        function smoothDown() {
          if (distance < total) {
            distance += step;
            document.body.scrollTop = distance;
            document.documentElement.scrollTop = distance;
            window.pageYOffset = distance;
            setTimeout(smoothDown, 10);
          } else {
            document.body.scrollTop = total;
            document.documentElement.scrollTop = total;
            window.pageYOffset = total;
          }
        }
        function smoothUp() {
          if (distance > total) {
            distance -= step;
            document.body.scrollTop = distance;
            document.documentElement.scrollTop = distance;
            window.pageYOffset = distance;
            setTimeout(smoothUp, 10);
          } else {
            document.body.scrollTop = total;
            document.documentElement.scrollTop = total;
            window.pageYOffset = total;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header_nav_box {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background: #080c14a6;
  .logo {
    cursor: pointer;
    img {
      width: 127px;
      height: 40px;
    }
  }
  .mean {
    background: #e95513;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 8px 14px;
    &:hover {
      transform: scale(0.8);
    }
    .name {
      text-align: center;
      margin-right: 5px;
      font-size: 12px;
    }
    .icon{
      display: flex;
    align-items: center;

    }
    .menu_icon {
      width: 18px;
      height: 13px;
    }
  }
}
.header_target {
  position: fixed;
  right: 50px;
  top: 25%;
  font-size: 14px;
  transform: translateY(-50%);
  background-color: rgba($color: #000, $alpha: 0.3);
  z-index: 90;
  width: 140px;
  height: 280px;
  padding-top: 10px;
  div {
    display: block;
    // height: 40px;
    text-align: center;
    cursor: pointer;
    padding: 10px 20px;
  }
}

</style>