<template>
  <div>
    <div class="wrap">
      <div class="demoout" :class="hasOrangeClass ? 'orange' : 'grey'"></div>
      <div class="circle">
        <slot></slot>
      </div>
      <div class="top"></div>
      <div class="bottom"></div>
    </div>
    <!-- <div :class="color">
      <div class="box_w">
        <slot></slot>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "red",
    },
    hasOrangeClass: {
      type: Boolean,
      default: false,
    },
  },
  name: "",
  components: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.wrap {
  position: relative;
  width: 160px;
  height: 160px;
  overflow: hidden;
}
.demoout {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  border: 3px solid transparent;
  border-radius: 50%;
  background: transparent;
  z-index: 8;
  &.grey {
    border-top-color: #727071;
    animation: circlez 4s infinite linear;
  }
  &.orange {
    border-top-color: #e95513;
    animation: circle 4s infinite linear;
  }
}

@-webkit-keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes circlez {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circle {
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.circle:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  top: 50%;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: transparent;
  border-radius: 0 0 50% 50%/ 0 0 100% 100%;
  z-index: 2;
}
.top,
.bottom {
  position: absolute;
  left: 0px;
  width: 100%;
  height: 60%;
  box-sizing: border-box;
  background: #fbfbfb;
}
.top {
  top: 0;
  z-index: 1;
  transform-origin: center bottom;
  animation: 0.5s back-half linear 0.5s;
  animation-fill-mode: forwards;
}
.bottom {
  z-index: 3;
  top: 50%;
  transform-origin: center top;
  animation: 0.5s front-half linear;
  animation-fill-mode: forwards;
}
@keyframes front-half {
  0% {
    transform: rotate(0);
  }
  99% {
    transform: rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: rotate(180deg);
    opacity: 0;
  }
}
@keyframes back-half {
  0% {
    transform: rotate(0);
  }
  99% {
    transform: rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: rotate(180deg);
    opacity: 0;
  }
}
</style>