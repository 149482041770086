<template>
  <div class="idea_box container" id="Idea">
    <div class="title flex-center-center">
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".2s">P</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".3s">h</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".4s">i</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".5s">l</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".6s">o</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".7s">s</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".8s">o</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration=".9s">p</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration="1s">h</div>
      <div class=" wow animate__animated animate__slideInUp" data-wow-duration="1.1s">y</div>
    </div>
    <div class="slogan">公司理念</div>
    <div class="slogan_text">诚心专注 成就每一个合作伙伴</div>
    <div class="logo">
      <div class="pic_item wow animate__animated animate__fadeInDown" data-wow-duration="1s">
        <img :src="require('@/assets/images/orange.png')" alt="" srcset="" width="100%" height="100%">
      </div>
      <div class="pic_item wow animate__animated animate__fadeInDown" data-wow-duration="1s">
        <img :src="require('@/assets/images/orange.png')" alt="" srcset="" width="100%" height="100%">
      </div>
      <div class="pic_item wow animate__animated animate__fadeInDown" data-wow-duration="1s">
        <img :src="require('@/assets/images/orange.png')" alt="" srcset="" width="100%" height="100%">
      </div>
    </div>
    <div class="logo_fill"></div>
    <div class="info">
      <div class="info_item" ><span class="header">使命: </span>为世界优秀品牌链接每个中国消费者</div>
      <div class="info_item" ><span class="header">愿景: </span>成为品牌数字化的长久合作伙伴</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeIdea",
};
</script>

<style scoped lang="scss">
.idea_box {
  width: 100%;
  // padding: 125px;
  background-color: #fff;
  text-align: center;
  position: relative;
  padding-top: 200px;
  .title {
      font-size: 77px;
      line-height: 77px; 
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #302e2e;
      margin-bottom: 10px;
      margin-left: 40px;
  }
  .slogan {
    margin-bottom: 40px;
    height: 27px;
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e95513;
  }
  .slogan_text {
    font-size: 40px;
    line-height: 40px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #313232;
  }
  .logo {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    .pic_item {
      overflow: hidden;
      &:nth-child(1) {
        height: 80px;
      }
      &:nth-child(2) {
        height: 120px;
      }
      img {
        width: 596px;
      }
    }
  }
  .logo_fill {
    height: 180px;
  }
  .info_item {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #505050;
    &:nth-child(1) {
      margin-bottom: 20px;
    }
    .header {
      color: #e95513;
    }
  }
}
</style>