<template>
  <div class="integrations-block__integration" @mouseenter="mouseenter">
    <div :class="`integrations-block__integration__transition-from ` + getClass" >
      <img :src="require('@/assets/images/' + src1 + '.png')" role="img" tabindex="0" data-aria-label="USPS" />
    </div>
    <div :class="`integrations-block__integration__transition-to ` + getClass" >
      <img :src="require('@/assets/images/' + src2 + '.png')" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    other: {
      type: Array,
      default: () => []
    },
    allImage: {
      type: Array,
      default: () => []
    },
    indexs: {
      type: Array,
      default: () => []
    },
    cur: {
      type: String,
      default: ''
    },
    nextIndex: {
      type: [Number, String],
      default: 5
    }
  },
  data () {
    return {
      position: "",
      position2: "",
      src1: "",
      src2: "",
      index: 0,
      all: [],
      run: true
    };
  },
  computed: {
    getClass () {
      return this.position ? "exiting-" + this.position : "";
    },
    getClass2 () {
      return this.position2 ? "exiting-" + this.position2 : "";
    },
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.all = [this.cur, ...this.other]
      this.src1 = this.cur
      this.src2 = this.cur
    },
    mouseenter () {
      if (this.run) {
        this.run = false
        this.position = "up";
        this.position2 = "up";
        this.src2 = this.allImage[this.nextIndex]
        this.$emit('change', this.src1)
        setTimeout(() => {
          this.position = "";
          this.position2 = "";
          this.src1 = this.src2
          this.run = true
        }, 700);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.integrations-block__integration {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}

.integrations-block__integration__transition-to {
  position: absolute;
  top: 0;
  -webkit-transform: rotate3d(1, 0, 0, 90deg);
  transform: rotate3d(1, 0, 0, 90deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-delay: 175ms;
  animation-delay: 175ms;
  height: 100px;
  width: 100px;
  &.exiting-up {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-name: container-entering-vertical;
    animation-name: container-entering-vertical;
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
  }
  &.exiting-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-name: container-entering-vertical;
    animation-name: container-entering-vertical;
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
  }
  &.exiting-left {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-animation-name: container-entering-horizontal;
    animation-name: container-entering-horizontal;
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
  }
  &.exiting-right {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-animation-name: container-entering-horizontal;
    animation-name: container-entering-horizontal;
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
  }
}
.integrations-block__integration__transition-from {
  height: 100px;
  width: 100px;
  &.exiting-up {
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-animation-name: container-exiting-vertical;
    animation-name: container-exiting-vertical;
    -webkit-transform: rotate3d(1, 0, 0, 90deg);
    transform: rotate3d(1, 0, 0, 90deg);
  }
  &.exiting-bottom {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-animation-name: container-exiting-vertical;
    animation-name: container-exiting-vertical;
    -webkit-transform: rotate3d(1, 0, 0, 90deg);
    transform: rotate3d(1, 0, 0, 90deg);
  }
  &.exiting-left {
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-animation-name: container-exiting-horizontal;
    animation-name: container-exiting-horizontal;
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
  &.exiting-right {
    -webkit-transform-origin: right;
    transform-origin: right;
    -webkit-animation-name: container-exiting-horizontal;
    animation-name: container-exiting-horizontal;
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
}

.integrations-block__integration__transition-from,
.integrations-block__integration__transition-to {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  height: 80px;
  width: 80px;
}
@-webkit-keyframes container-exiting-vertical {
    0% {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg)
    }

    to {
        -webkit-transform: rotate3d(1, 0, 0, 90deg);
        transform: rotate3d(1, 0, 0, 90deg)
    }
}

@keyframes container-exiting-vertical {
    0% {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg)
    }

    to {
        -webkit-transform: rotate3d(1, 0, 0, 90deg);
        transform: rotate3d(1, 0, 0, 90deg)
    }
}

@-webkit-keyframes image-exiting-up {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }
}

@keyframes image-exiting-up {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }
}

@-webkit-keyframes image-exiting-down {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }
}

@keyframes image-exiting-down {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }
}

@-webkit-keyframes container-exiting-horizontal {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 1, 0, -90deg);
        transform: rotate3d(0, 1, 0, -90deg)
    }
}

@keyframes container-exiting-horizontal {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 1, 0, -90deg);
        transform: rotate3d(0, 1, 0, -90deg)
    }
}

@-webkit-keyframes image-exiting-left {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }
}

@keyframes image-exiting-left {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }
}

@-webkit-keyframes image-exiting-right {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }
}

@keyframes image-exiting-right {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }
}

@-webkit-keyframes container-entering-vertical {
    0% {
        -webkit-transform: rotate3d(1, 0, 0, 90deg);
        transform: rotate3d(1, 0, 0, 90deg)
    }

    to {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg)
    }
}

@keyframes container-entering-vertical {
    0% {
        -webkit-transform: rotate3d(1, 0, 0, 90deg);
        transform: rotate3d(1, 0, 0, 90deg)
    }

    to {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg)
    }
}

@-webkit-keyframes image-entering-up {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes image-entering-up {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@-webkit-keyframes image-entering-down {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes image-entering-down {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@-webkit-keyframes container-entering-horizontal {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, -90deg);
        transform: rotate3d(0, 1, 0, -90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg)
    }
}

@keyframes container-entering-horizontal {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, -90deg);
        transform: rotate3d(0, 1, 0, -90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg)
    }
}

@-webkit-keyframes image-entering-left {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes image-entering-left {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, -90deg);
        transform: rotate3d(0, 0, 1, -90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@-webkit-keyframes image-entering-right {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}

@keyframes image-entering-right {
    0% {
        -webkit-transform: rotate3d(0, 0, 1, 90deg);
        transform: rotate3d(0, 0, 1, 90deg)
    }

    to {
        -webkit-transform: rotate3d(0, 0, 1, 0deg);
        transform: rotate3d(0, 0, 1, 0deg)
    }
}
.integrations-block.theme-dark-background {
  background-color: #000;
}
.integrations-block__constrained-container {
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
}
@media all and (min-width: 1480px) and (max-width: 1600px) {
  .integrations-block__constrained-container {
    max-width: 1360px;
  }
}
@media all and (min-width: 768px) {
  .integrations-block__constrained-container {
    padding-top: 48px;
    padding-bottom: 36px;
  }
}
@media all and (min-width: 1024px) {
  .integrations-block__constrained-container {
    padding-top: 64px;
    padding-bottom: 48px;
  }
}
@media all and (min-width: 1280px) {
  .integrations-block__constrained-container {
    padding-top: 80px;
    padding-bottom: 56px;
  }
}
@media all and (min-width: 1440px) {
  .integrations-block__constrained-container {
    padding-top: 80px;
    padding-bottom: 56px;
  }
}
@media all and (min-width: 768px) {
  .integrations-block__constrained-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (min-width: 1024px) {
  .integrations-block__constrained-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (min-width: 1280px) {
  .integrations-block__constrained-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (min-width: 1440px) {
  .integrations-block__constrained-container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (min-width: 768px) {
  .integrations-block .feature-text__inner-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
}
.integrations-block .feature-text__inner-wrapper h2:first-child {
  margin-top: -0.125em;
}
.integrations-block .feature-text__inner-wrapper p {
  max-width: 25em;
}
.integrations-block__img-srcs {
  display: none;
}
.integrations-block__integrations {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 42px;
}
@media all and (min-width: 768px) {
  .integrations-block__integrations {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 24px;
  }
}
@media all and (min-width: 1024px) {
  .integrations-block__integrations {
    margin-top: 40px;
  }
}
@media all and (min-width: 1280px) {
  .integrations-block__integrations {
    margin-top: 58px;
  }
}
.integrations-block__integration {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 33%;
  display: none;
}
.integrations-block__integration:nth-child(-n + 6) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media all and (min-width: 768px) {
  .integrations-block__integration {
    width: calc((((100% - 200px) / 6) * 1) + 0px + 0px);
  }
}
.integrations-block__integration:first-of-type,
.integrations-block__integration:nth-of-type(2),
.integrations-block__integration:nth-of-type(3) {
  margin-bottom: 30px;
}
@media all and (min-width: 768px) {
  .integrations-block__integration:first-of-type,
  .integrations-block__integration:nth-of-type(2),
  .integrations-block__integration:nth-of-type(3) {
    margin-bottom: 0;
  }
}
.integrations-block__integration__transition-from,
.integrations-block__integration__transition-to {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  height: 80px;
  width: 80px;
}
.integrations-block__integration__transition-from img,
.integrations-block__integration__transition-to img {
  height: auto;
  width: 100%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.integrations-block__integration__transition-from.exiting-up {
  -webkit-animation-name: container-exiting-vertical;
  animation-name: container-exiting-vertical;
  -webkit-transform-origin: top;
  transform-origin: top;
}
.integrations-block__integration__transition-from.exiting-up img {
  -webkit-animation-name: image-exiting-up;
  animation-name: image-exiting-up;
}
.integrations-block__integration__transition-from.exiting-down {
  -webkit-animation-name: container-exiting-vertical;
  animation-name: container-exiting-vertical;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
.integrations-block__integration__transition-from.exiting-down img {
  -webkit-animation-name: image-exiting-down;
  animation-name: image-exiting-down;
}
.integrations-block__integration__transition-from.exiting-left {
  -webkit-animation-name: container-exiting-horizontal;
  animation-name: container-exiting-horizontal;
  -webkit-transform-origin: left;
  transform-origin: left;
}
.integrations-block__integration__transition-from.exiting-left img {
  -webkit-animation-name: image-exiting-left;
  animation-name: image-exiting-left;
}
.integrations-block__integration__transition-from.exiting-right {
  -webkit-animation-name: container-exiting-horizontal;
  animation-name: container-exiting-horizontal;
  -webkit-transform-origin: right;
  transform-origin: right;
}
.integrations-block__integration__transition-from.exiting-right img {
  -webkit-animation-name: image-exiting-right;
  animation-name: image-exiting-right;
}
.integrations-block__integration__transition-to {
  position: absolute;
  top: 0;
  -webkit-transform: rotate3d(1, 0, 0, 90deg);
  transform: rotate3d(1, 0, 0, 90deg);
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-delay: 175ms;
  animation-delay: 175ms;
}
.integrations-block__integration__transition-to img {
  -webkit-animation-delay: 175ms;
  animation-delay: 175ms;
}
.integrations-block__integration__transition-to.entering-up {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  -webkit-animation-name: container-entering-vertical;
  animation-name: container-entering-vertical;
  -webkit-transform: rotate3d(1, 0, 0, 90deg);
  transform: rotate3d(1, 0, 0, 90deg);
}
.integrations-block__integration__transition-to.entering-up img {
  -webkit-animation-name: image-entering-up;
  animation-name: image-entering-up;
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
  transform: rotate3d(0, 0, 1, -90deg);
}
.integrations-block__integration__transition-to.entering-down {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-name: container-entering-vertical;
  animation-name: container-entering-vertical;
  -webkit-transform: rotate3d(1, 0, 0, 90deg);
  transform: rotate3d(1, 0, 0, 90deg);
}
.integrations-block__integration__transition-to.entering-down img {
  -webkit-animation-name: image-entering-down;
  animation-name: image-entering-down;
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
  transform: rotate3d(0, 0, 1, -90deg);
}
.integrations-block__integration__transition-to.entering-left {
  -webkit-transform-origin: right;
  transform-origin: right;
  -webkit-animation-name: container-entering-horizontal;
  animation-name: container-entering-horizontal;
  -webkit-transform: rotate3d(1, 0, 0, 90deg);
  transform: rotate3d(1, 0, 0, 90deg);
}
.integrations-block__integration__transition-to.entering-left img {
  -webkit-animation-name: image-entering-left;
  animation-name: image-entering-left;
  -webkit-transform: rotate3d(0, 0, 1, -90deg);
  transform: rotate3d(0, 0, 1, -90deg);
}
.integrations-block__integration__transition-to.entering-right {
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-animation-name: container-entering-horizontal;
  animation-name: container-entering-horizontal;
  -webkit-transform: rotate3d(1, 0, 0, 90deg);
  transform: rotate3d(1, 0, 0, 90deg);
}
.integrations-block__integration__transition-to.entering-right img {
  -webkit-animation-name: image-entering-right;
  animation-name: image-entering-right;
  -webkit-transform: rotate3d(0, 0, 1, 90deg);
  transform: rotate3d(0, 0, 1, 90deg);
}

</style>