<template>
  <div
    class="about_box container wow animate__animated animate__fadeIn"
    data-wow-duration="2s"
    id="About"
  >
    <div class="about_left flex-center-center">
      <div class="circle">
        <CircleDetail color="red" class="red">
          <div class="text">橙玖</div>
          <div class="area">
            <div class="area-title">公司分布</div>
            <div class="area-org">上海、广州</div>
          </div>
        </CircleDetail>
        <CircleDetail color="grey" hasOrangeClass class="grey">
          <div class="text">
            <div class="text-title">创立时间</div>
            <div class="text-year">2020</div>
          </div>
        </CircleDetail>
      </div>
    </div>
    <div class="about_right">
      <div class="title flex-center">
        <div
          class="wow animate__animated animate__slideInUp"
          data-wow-duration=".2s"
        >
          A
        </div>
        <div
          class="wow animate__animated animate__slideInUp"
          data-wow-duration=".3s"
        >
          b
        </div>
        <div
          class="wow animate__animated animate__slideInUp"
          data-wow-duration=".4s"
        >
          o
        </div>
        <div
          class="wow animate__animated animate__slideInUp"
          data-wow-duration=".5s"
        >
          u
        </div>
        <div
          class="wow animate__animated animate__slideInUp"
          data-wow-duration=".6s"
        >
          t
        </div>
      </div>
      <div class="desc">关于橙玖</div>
      <div class="slogan">
        <div class="slogan_title">共建共创，合作共赢!</div>
        <div class="slogan_title_en">
          Create together to achieve win-win cooperation!
        </div>
      </div>
      <div class="detail">
        橙玖科技成立于2020年，自公司成立起即以“品销合一”为企业宗旨，致力于利用技术将品牌360°整合营销服务与电商运营服务紧密结合，秉承“为世界优秀品牌链接每位中国消费者”的使命为全球众多知名品牌提供品牌营销综合解决方案。强调以客户为中心，提供全链路、全渠道、全场景的数字化服务，成为品牌数字化的生态合作伙伴。
      </div>
    </div>
  </div>
</template>

<script>
import CircleDetail from "./cricle/index";
export default {
  name: "HomeAbout",
  components: { CircleDetail },
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
};
</script>

<style scoped lang="scss">
.about_box {
  width: 100%;
  padding: 125px;
  background-color: #fbfbfb;
  display: flex;
  .about_left {
    flex: 0 0 50%;
    .circle {
      width: 375px;
      height: 375px;
      position: relative;
    }
    .red {
      position: absolute;
      bottom: 0;
      left: 0;
      .text {
        position: absolute;
        z-index: 8;
        right: 36px;
        top: 49px;
        // width: 48px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e95513;
      }
      .area {
        position: absolute;
        z-index: 8;
        left: 49px;
        bottom: 49px;
        .area-title {
          // height: 14px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #727272;
        }
        .area-org {
          // height: 26px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #313232;
        }
      }
    }
    .grey {
      position: absolute;
      top: 0;
      right: 0;
      .text {
        position: absolute;
        z-index: 8;
        right: 49px;
        top: 49px;
      }
      .text-title {
        // height: 15px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #727272;
      }
      .text-year {
        // width: 55px;
        // height: 21px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #313232;
      }
    }
  }
  .about_right {
    flex: 0 0 50%;
    .title {
      // width: 186px;
      font-size: 77px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #302e2e;
    }
    .desc {
      // width: 100px;
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #e95513;
    }
    .slogan {
      margin-top: 35px;
      .slogan_title {
        // width: 237px;
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #313232;
      }
      .slogan_title_en {
        // width: 366px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #313232;
        margin-top: 12px;
      }
    }
    .detail {
      font-size: 14px;
      // height: 100%;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3b3b3d;
      line-height:1.2;
      margin-top: 30px;
    }
  }
}
</style>