<template>
  <div id="Top">
    <div class="full_box header_bg container">
      <img src="../../../assets/images/logo.png" alt="图片预览加载中" class="imgs">
      <div class="title">
        <span class="text flex-center-center">预见<span class="circle"></span>未来</span>
      </div>
      <div class="desc">以技术为先，为企业提供360°整体解决方案</div>
      <div class="desc-en">
        Based on technology, provide 360° overall solution for the company
      </div>
      <bcanvas />
      <HeaderNav />
    </div>
  </div>
</template>

<script>
import HeaderNav from "./header-nav/index.vue";
import bcanvas from "./canvas.vue"
export default {
  name: "HomeHeader",
  components: { bcanvas, HeaderNav },
};
</script>

<style scoped lang="scss">

.full_box {
  height: 700px;
  position: relative;
}
.imgs {
  width: 128px;
  height: 38px;
  margin-top: 20px;
}
.header_bg {
  background-color: #080c14;
  .title {
    // height: 250px;
    text-align: center;
    font-size: 138px;
    padding: 180px 0 50px;
    // font-family: OPPOSans;
    font-family:Arial;
    .text {
      background-image: -webkit-linear-gradient(top, #fff 15%, #080c14 85%);
      background-clip:text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 15px;
      font-weight: lighter;
      .circle {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #e75321;
        margin: 0 30px 0 15px;
        display: block;
      }
    }
  }
  .desc {
    text-align: center;
    font-size: 24px;
    // font-family: OPPOSans;
    font-family: Microsoft YaHei;
    font-weight: lighter;
    color: #e95513;
    line-height: 26px;
    margin-bottom: 25px;
    letter-spacing: 3px;
  }
  .desc-en {
    width: 570px;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    opacity: 0.49;
    letter-spacing: 3px;
    font-family: Microsoft YaHei;
    margin: 0 auto;
  }
}
</style>